














import Vue from "vue";
import { ResumeListingTHead } from "@/interfaces/global_valley/resume_parser/resume_listing/resume_listing_thead";

export default Vue.extend({
  name: "ResumeListingTHead",
  data(): ResumeListingTHead {
    return {
      table_headers: [
        {
          title: "Title"
        },
        {
          title: "Status"
        },
        {
          title: "Date"
        },
        {
          title: "Actions"
        }
      ]
    };
  }
});
