






































































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { date_format } from "@/utils/skill_extractor";
import { ResumeListingTBody } from "@/interfaces/global_valley/resume_parser/resume_listing/resume_listing_tbody";
import {
  DELETE_UPLOADED_RESUME,
  GET_RESUME_LISTING,
  GET_RESUME_LISTING_LOADING
} from "@/store/modules/resume_parser/constants";
import {
  Resume,
  ResumeListingActionPayload
} from "@/store/modules/resume_parser/interfaces";

export default Vue.extend({
  name: "ResumeListingTBody",
  data(): ResumeListingTBody {
    return {
      pagination: 1
    };
  },
  async mounted() {
    await this.extract_resumes();
  },
  computed: {
    ...mapGetters("resume_parser", {
      get_resumes: GET_RESUME_LISTING,
      listing_resume_loading: GET_RESUME_LISTING_LOADING
    })
  },
  methods: {
    ...mapActions("resume_parser", {
      fetch_resumes: GET_RESUME_LISTING,
      delete_uploaded_resume: DELETE_UPLOADED_RESUME
    }),
    date_format,
    async extract_resumes(page = 1, limit = 10) {
      const obj: ResumeListingActionPayload = {
        page: page - 1,
        limit: limit
      };
      await this.fetch_resumes(obj);
    },
    format_file_name(value: string): string {
      return value.split("-").slice(1).join(" ");
    },
    async delete_resume(resume: Resume) {
      await this.delete_uploaded_resume(resume.id);
    }
  }
});
