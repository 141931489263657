
























import Vue from "vue";
import ResumeListingTBody from "@/components/global_valley/resume_parser/resume_listing/ResumeListingTBody.vue";
import ResumeListingTHead from "@/components/global_valley/resume_parser/resume_listing/ResumeListingTHead.vue";

export default Vue.extend({
  name: "ResumeListing",
  components: {
    ResumeListingTHead,
    ResumeListingTBody
  }
});
